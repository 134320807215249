import { For } from "solid-js";
import { AStageModule, ModuleDetails } from "../../models";

export function ModuleInfo(props?: AStageModule) {
  const entries = Object.entries(props);
  return (
    <div class="w-full h-fit flex flex-col space-y-2">
      <For each={entries}>
        {([k, v]) => {
          if (typeof v === "object") return null;
          return (
            <div class="w-full h-full flex flex-row space-x-2">
              <p>{k}</p>:<p>{v}</p>
            </div>
          );
        }}
      </For>
    </div>
  );
}
